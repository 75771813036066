import React, { Component } from "react"
//import styled from "styled-components"

export default class Footer extends Component {
  render = () => (
    <div id="footer">
      <div id="copyright">Copyright @ 2020, szubka.com</div>
    </div>
  )
}
