import React, { Component } from "react"
import styled from "styled-components"
//import { ParallaxBanner } from "react-scroll-parallax"
import { Link } from "react-router-dom"
//import { LinkedinSquare } from "styled-icons/boxicons-logos"
import { Linkedin } from "styled-icons/fa-brands"

const Btn = styled.span`
  display: inline-block;
  margin: 10px;
  padding: 6px;
  border: solid 2px #6abdc5;
  border-radius: 6px;
  color: #6abdc5;
  font-weight: 500;
  font-size: 0.8em;
`

export default class About extends Component {
  renderLink = () => {
    return (
      <div>
        <Link to={this.props.linkSource}>
          <Btn>{this.props.linkTitle}</Btn>
        </Link>
      </div>
    )
  }

  render = () => {
    return (
      <div className="section flex flexColumn centerChildren containerType2">
        <div style={{ margin: "0 auto 0 auto" }}>
          <h2 style={{ marginTop: "40px", textAlign: "center" }}>
            A Hello and Welcome from Michael
          </h2>

          <div>
            <div className="flex flexRow">
              <div className="aboutCol">

                <p>
                  I'm a professional contractor located in CT, USA and
                  servicing organizations nationwide. Essentially, the focus is
                  the development of large-scale enterprise projects leveraging
                  state of the art technologies. Of particular interest has been
                  the creation, employment and direction of advanced
                  JavaScript-based frameworks/libraries which are now prevalent
                  across the stack and the foundation of nearly all modern
                  projects. Overall, I'm simply someone who loves to build
                  products and services with great technologies for amazing
                  companies.
                </p>
              </div>
              <div className="aboutCol2" style={{ margin: "0 0px 0 50px" }}>
                <div className="profilePic"></div>
                <div
                  className="flex flexRow centerChildren"
                  style={{
                    width: "120px",
                    margin: "40px 0 0 0",
                    alignSelf: "center",
                  }}
                >
                  <Linkedin
                    style={{
                      height: "25px",
                      color: "#6ebdc4",
                      cursor: "pointer",
                      margin: "0 10px 0 0",
                    }}
                  />
                  <div>mikeszubka</div>
                </div>
                <div
                  style={{
                    color: "#999",
                    fontSize: "10pt",
                    margin: "20px 0 0 0",
                    width: "180px",
                  }}
                >
                  {/* under msg removed since no longer needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
