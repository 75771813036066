import React, { Component } from "react"
import Header from "../header/Header"
import Footer from "../footer/Footer"
import Feature from "../feature/Feature"

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
}

export default class A1View extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    window.scrollTo(0, 0)
  }

  updateWindowDimensions = (self) => {
    //console.log("width: ", window.innerWidth)
    //console.log("height: ", window.innerHeight)
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  manageWidth = (w) => {
    return this.state.width < w ? this.state.width : w
  }

  render = () => {
    return (
      <div>
        <Header />
        <div data-scroll>
          <div className="content">
            <h2 className="item__caption-title">
              The A1 Prototype UI Experimental
            </h2>
            <p className="item__caption-copy">
              Welcome to "The Lab" where there's usually some sort of
              experimentation going on for the sake of exploration, learning and
              discovery. Really I can't remember the last time I had time for an
              experiment but here goes. We'll be using the game of Chess since
              it has historically been the ideal specimen for testing a large
              spectrum of technologies. Most notably IBM and Google in the AI
              category.
            </p>

            <Feature
              image={"./images/a1-1.jpg"}
              amount={0}
              height={"358px"}
              numbered={false}
              tight={true}
              desc={
                "Here's what I have so far. Its getting there. A little bit about how we got to this point follows."
              }
            />

            <p className="item__caption-copy">
              For this lab we'll be leveraging the ubiquitous nature of
              JavaScript and use it for just about everything. Frontend, backend
              and everything in-between including 3D and AI. We'll go a little
              cutting edge to shake out any new advantages or limitations
              reaching the market within the approach. We'll kick this off as a
              React App and start with a traditional 2D interface. For the
              techies, I usually don't get too detailed with these until the end
              but the stack will likely include at least React, Node, Express,
              AWS Linux Server, INGX, MySQL, GitHub and maybe GitLab.
            </p>

            <Feature
              image={"./images/a1-2.jpg"}
              amount={0}
              height={"345px"}
              numbered={false}
              tight={true}
              desc={"Update 1: Starting by keeping it simple. Traditional 2D."}
            />

            <p className="item__caption-copy">
              Like most things, chess has primarily moved online. One problem
              with that is we lose the artistry of the chess set itself.
              Everything online is played on a 2D grid. Just about everything
              labeled 3D is essentially using the same 2D grid but with static
              3D imagery to give it some perspective. We'll attempt to improve
              upon that concept by creating a true 3D environment.
            </p>

            <Feature
              image={"./images/a1-3.jpg"}
              amount={0}
              height={"358px"}
              numbered={false}
              tight={true}
              desc={
                "Update 2: The 3D chess set is born. We gave it a logo and placeholder name for now."
              }
            />

            <p className="item__caption-copy">
              Admittedly there's a lot of work here: domain logic/business
              rules/validation, movement/animation and of course the 3D UI
              itself, you name it. The package chess.js helped a bit for
              validation. Chessboard.jsx was used for 2D but isn't applicable
              for 3D. Essentially I ended up creating a new system partially for
              the sake of experimentation but also necessity.
            </p>
            <p className="item__caption-copy-etc">
              We're going to focus on the asthetics for a while since 3D opens
              up a world of possibilities. For example, one thing I wanted to
              add was the concept of "scenes". The screenshot above depicts the
              first scene. For the next scene I wanted to create a replica of
              "the amber board" which appearntly belonged to King Charles I. It
              has quite a backstory and ended up selling at auction for just
              under 1 Mil USD. Its a beauty so lets bring it to life.
            </p>

            <Feature
              image={"./images/a1-4.jpg"}
              amount={0}
              height={"358px"}
              numbered={false}
              tight={true}
              desc={
                "Update 3: A replica of the early 17th century King Charles Amber Board with modern pieces."
              }
            />
            <br />
            <br />
            <Feature
              image={"./images/a1-5.jpg"}
              amount={0}
              height={"358px"}
              numbered={false}
              tight={true}
              desc={
                "Camera controls anchored to perspective of player in chair for a touch of realism. "
              }
            />

            <br />
            <br />
            <Feature
              image={"./images/a1-6.jpg"}
              amount={0}
              height={"358px"}
              numbered={false}
              tight={true}
              desc={
                "Lean left, right, up, down or combo for a closer look at the game in action."
              }
            />

            <p className="item__caption-copy">
              That's all for now folks. Updates coming soon regarding AI,
              backend and the hosted solution.
            </p>

            <div style={{ margin: "0 0 100px 0" }}></div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
