import React, { Component } from "react"
import "./css/themes/base/base.css"
//import "./css/themes/base/base2024.css"

import {
  //withRouter, 
  Switch,
  Route
} from "react-router-dom"
import HomeContainer from "./containers/HomeContainer"
import A1Container from "./containers/A1Container"
import KeywordContainer from "./containers/KeywordContainer"

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions = (self) => {
    //console.log("width: ", window.innerWidth)
    //console.log("height: ", window.innerHeight)
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentDidMount() {
    this.updateWindowDimensions(this)
    window.addEventListener("resize", () => {
      this.updateWindowDimensions(this)
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  manageWidth = (w) => {
    return this.state.width < w ? this.state.width : w
  }

  render() {
    return (
      <div id="screen">
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/a1" component={A1Container} />
          <Route exact path="/keyword" component={KeywordContainer} />
        </Switch>
      </div>
    )
  }
}

export default App
