import React, { Component } from "react"
import styled from "styled-components"
import { ParallaxBanner } from "react-scroll-parallax"
import { Link } from "react-router-dom"

/*
const ImageWrap = styled.div`
  --aspect-ratio: 8/10;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  max-width: calc(100% - 2rem);
  will-change: transform;
`
*/

const Btn = styled.span`
  display: inline-block;
  margin: 10px;
  padding: 6px;
  border: solid 2px #6abdc5;
  border-radius: 6px;
  color: #6abdc5;
  font-weight: 500;
  font-size: 0.8em;
`

export default class Feature extends Component {
  renderLink = () => {
    return (
      <div>
        <Link to={this.props.linkSource}>
          <Btn>{this.props.linkTitle}</Btn>
        </Link>
      </div>
    )
  }
  render = () => {
    const { numbered } = this.props
    const { tight } = this.props

    return (
      <div style={{ width: '100%' }} className={numbered ? "item" : ""}>
        <ParallaxBanner
          layers={[
            {
              image: this.props.image,
              amount: this.props.amount,
            },
          ]}
          style={{
            //width: this.props.width ? `${this.props.width}px` : "600px",
            width: "100%",
            height: this.props.height,
            borderTop: "solid 6px #01949e",
          }}
        >
          {/* <h1>Banner Children</h1> */}
        </ParallaxBanner>
        {/* <div className="item__img item__img--t1"></div> */}

        <div className={tight ? "item__tight" : "item__caption"}>
          <h2 className="item__caption-title">{this.props.title}</h2>
          <p
            style={{ padding: "0px 10px 10px 10px", width: "100%" }}
            className={tight ? "" : "item__caption-copy"}
          >
            {this.props.desc}
            {this.props.linkTitle && this.renderLink()}
          </p>
        </div>
      </div>
    )
  }
}
