import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import { ParallaxProvider } from "react-scroll-parallax"
import { BrowserRouter } from "react-router-dom"

ReactDOM.render(
  <ParallaxProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ParallaxProvider>,
  document.getElementById("root")
)
registerServiceWorker()
