import React, { Component } from "react"
//import styled from "styled-components"
import { Link } from "react-router-dom"

export default class Header extends Component {
  render = () => (
    <div id="header" className="flex centerChildren">
      <Link to="/">
        <div className="logo"></div>
      </Link>
    </div>
  )
}
