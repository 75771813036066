import React, { Component } from "react"
//import { ParallaxBanner } from "react-scroll-parallax"
//import styled from "styled-components"
import Feature from "../feature/Feature"
//import Portfolio from "../feature/Portfolio"
import Lab from "../feature/Lab"
import About from "../about/About"
import Header from "../header/Header"
import Footer from "../footer/Footer"
//import { withRouter, Switch, Route } from "react-router-dom"

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
}

export default class HomeView extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions = (self) => {
    //console.log("width: ", window.innerWidth)
    //console.log("height: ", window.innerHeight)
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentDidMount() {
    this.updateWindowDimensions(this)
    window.addEventListener("resize", () => {
      this.updateWindowDimensions(this)
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  manageWidth = (w) => {
    return this.state.width < w ? this.state.width : w
  }

  render = () => {
    return (
      <div className="section center">
        <Header />
        <div data-scroll>
          {/* style={{ width: "100%" }} */}
          <div className="content">
            {/* 
            START HERE 
            All features go here 
            */}
            {/* A1 */}
            <Feature
              image={"./images/a1.jpg"}
              amount={0.2}
              height={"300px"}
              //width={this.manageWidth(900)}
              //width={"100%"}
              width="100%"
              numbered={true}
              title={"The A1 Prototype"}
              //linkTitle={"View Experiment"}
              //linkSource={"/a1"}
              desc={
                `
                Inspired by Deepmind's AlphaZero, the A-One Prototype is a small AI/ML experiment applied to chess.
                It's accompanied by a custom browser based 3D interface attempting to push the limits of whats possible.`
              }
            />

            {/* O2 */}
            <Feature
              image={"./images/O2.jpg"}
              amount={0.2}
              height={"500px"}
              //width={this.manageWidth(900)}
              width="100%"
              numbered={true}
              title={"LOMS"}
              desc={
                `Project codenamed: LOMS, where I helped lead the development of a 
                large, multi-year Logistics Operations Management System project for a +50 billion market cap global enterprise. 
                This system manages logistics operations for the sourcing, storage, equipment, driver and delivery to customers 
                all over the world.`
              }
            />


            {/* HOOPLA */}
            <Feature
              image={"./images/hoopla.jpg"}
              amount={0.2}
              height={"500px"}
              //width={this.manageWidth(900)}
              width="100%"
              numbered={true}
              title={"Hoopla"}
              desc={
                `Digitizing public libraries to instantly borrow your favorite movies, tv shows, music and eBooks. 
                A complete rewrite with a fun, hardworking, close-knit team. `
              }
            />
            {/* MIX DASH */}
            <Feature
              image={"./images/mixDash.jpg"}
              amount={0.2}
              height={"500px"}
              //width={this.manageWidth(800)}
              width="100%"
              numbered={true}
              title={"MixDash"}
              desc={`Project code-name "Mixdash" is a highly customizable Security Dashboard for the enterprise.`}
            />

            {/* ESPN */}
            <Feature
              image={"./images/espn2.jpg"}
              amount={0.3}
              height={"400px"}
              //width={this.manageWidth(900)}
              width="100%"
              numbered={true}
              title={"ESPN"}
              desc={
                "From consumer-facing to internal global enterprise systems and everything in-between. The latest is a new interface that helps manage ESPN's enormous library of content."
              }
            />


            {/* AquaTREND */}
            <Feature
              image={"./images/aquaTrend.jpg"}
              amount={0.2}
              height={"500px"}
              //width={this.manageWidth(900)}
              width="100%"
              numbered={true}
              title={"AquaTREND"}
              desc={`Project codename: AquaTREND, a dive into the financial quantitative realm. This system is designed to capture "big moves" in the stock market which its incredibly 
              adept at doing so...autonomously. Perhaps one of the most intriguing, yet relatively small projects I've managed.`}
            />


            <h2 style={{ textAlign: "center" }}>
              Every So Often We Highlight 5 Works Above
            </h2>

            <div
              style={{
                margin: "0 0 40px 0",
                color: "#999",
                //maxWidth: "550px",
                width: "100%",
                lineHeight: "1.7",
              }}
            >
              <div style={{ margin: "0 20px 20px 20px" }}>
                Above we highlight recent works ESPN, HOOPLA and solutions for businesses with +50 Billion Market Cap to early series level funded startups.
              </div>

            </div>
            <Lab />
            <About />

            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
