import React, { Component } from "react"
import HomeView from "../components/Home/HomeView"
class HomeContainer extends Component {
  render() {
    return (
      <div style={{ width: '100%' }} className="flex centerChildren">
        <HomeView />
      </div>
    )
  }
}

export default HomeContainer
