import React, { Component } from "react"
import A1View from "../components/A1/A1View"

class A1Container extends Component {
  render() {
    return (
      <div>
        <A1View />
      </div>
    )
  }
}

export default A1Container
