import React, { Component } from "react"
//import styled from "styled-components"
import { orderBy } from "lodash-es"
import { ignoreWords } from "./ignoreWords"

export default class About extends Component {
  constructor(props) {
    super(props)
    this.state = { inputVal: "", output: [] }
  }

  inputChange = (e) => {
    this.setState({ inputVal: e.target.value })
  }

  isIgnoreWord = (word) => {
    const w = ignoreWords.filter((item) => item === word)
    return w.length ? true : false
  }

  parseInput = () => {
    const splitVal = this.state.inputVal.split(" ")
    let words = []

    //Loop through all the words
    for (let i = 0; i < splitVal.length; i++) {
      //splitVal[i]

      splitVal[i] = splitVal[i].toLowerCase()
      splitVal[i] = splitVal[i].replace(",", "")

      if (splitVal[i].charAt(splitVal[i].length - 1) === ".") {
        splitVal[i] = splitVal[i].slice(0, -1)
      }
      splitVal[i] = splitVal[i].trim()

      if (this.isIgnoreWord(splitVal[i])) {
        continue
      }
      let found = false
      for (let y = 0; y < words.length; y++) {
        //console.log("words[y].word = ", words[y].word, y)
        if (words[y].word) {
          //console.log("compare: ", words[y].word + " === " + splitVal[i])
          if (words[y].word === splitVal[i]) {
            found = true
            words[y].count = words[y].count + 1
          }
        }
      }

      if (!found) {
        words.push({
          word: splitVal[i],
          count: 1,
          ignore: this.isIgnoreWord(splitVal[i]),
        })
      }
    }

    words = orderBy(words, ["count"], ["desc"])
    //console.log("words: ", words)
    this.setState({ output: words })
  }

  renderOutput = () => {
    return this.state.output.map((item, index) => {
      return (
        <div key={`key_${index}`}>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>{item.count}</td>
                  <td>{item.word}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )
    })
  }

  render = () => {
    return (
      <div className="section flex flexColumn centerChildren containerType2">
        <div className="flex flexRow" style={{ padding: "30px 0 0 0" }}>
          <textarea
            id="input"
            style={{ width: "500px", height: "300px" }}
            onChange={(e) => this.inputChange(e)}
          />

          <div id="output">{this.renderOutput()}</div>
        </div>
        <div>
          <input
            onClick={(e) => this.parseInput(e)}
            type="submit"
            value="submit"
          />
        </div>
      </div>
    )
  }
}
