import React, { Component } from "react"
import Keyword from "../components/keyword/Keyword"

class KeywordContainer extends Component {
  render() {
    return (
      <div>
        <Keyword />
      </div>
    )
  }
}

export default KeywordContainer
