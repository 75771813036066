export const ignoreWords = [
  "",
  "&",
  "—",
  "-",
  "a",
  "an",
  "and",
  "as",
  "at",
  "for",
  "from",
  "has",
  "if",
  "in",
  "is",
  "of",
  "on",
  "or",
  "that",
  "the",
  "to",
  "you",
  "your",
  "with",
]
