import React, { Component } from "react"

//import styled from "styled-components"
import Feature from "./Feature"

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
}

export default class Lab extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions = (self) => {
    //console.log("width: ", window.innerWidth)
    //console.log("height: ", window.innerHeight)
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentDidMount() {
    this.updateWindowDimensions(this)
    window.addEventListener("resize", () => {
      this.updateWindowDimensions(this)
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  manageWidth = (w) => {
    return this.state.width < w ? this.state.width : w
  }

  render = () => (
    <div
      id="lab"
      data-scroll
      className="flex flexColumn centerChildren containerType2"
      style={{ marginBottom: "20px" }}
    >
      <div style={{ margin: "20px auto 0 auto" }}>
        <h2 style={{ marginTop: "40px", textAlign: "center" }}>Experimental</h2>
        <Feature
          image={"./images/amberBoard.jpg"}
          amount={0.3}
          height={"300px"}
          //width={this.manageWidth(800)}
          width={"100%"}
          numbered={false}
          title={""}
          tight={true}
          desc={`Screenshot: actual playable replica of the early 17th century King Charles amber chessboard with modern gold & silver pieces.`}
        />
        <p
          style={{
            // maxWidth: "500px",
            color: "#999",
            lineHeight: 1.7,
            margin: "0 20px 20px 20px",
          }}
        >

          This is A-One, highlighted above. To echo Demis Hassabis of DeepMind who summed it up quite nicely, "this endeavor isn't solely about conquering games;
          rather, it serves as a challenging benchmark to gauge our progress. It serves as a crucial stepping stone toward developing versatile
          algorithms applicable across various industries to bring about significant advancements for society".
          <br /><br />
          A live 3D chess world, built from the ground up, to test the limits of the latest technologies. Details & findings coming soon.



        </p>
      </div>



      <p
        style={{
          maxWidth: "500px",
          color: "#999",
          lineHeight: 1.7,
          margin: "0 20px 50px 20px",
        }}
      >


      </p>
    </div>
  )
}
